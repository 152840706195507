<template>
  <div> 
    <!-- 添加标签组 -->
		<el-dialog title="添加标签组" :visible="addTagListshow" v-loading="addTagListLoading" width="500px"
			@close="addTagListClosed">
			<div style="max-height:500px;overflow-y:auto">
        <el-form :model="addListruleForm" :rules="addListrules" ref="addTagListForm">
          <el-form-item prop="Name" label="标签组名称:" label-width="100px">
						<el-input v-model.trim="addListruleForm.Name" placeholder="请输入标签组名称" :maxlength="maxlength" @input="limit30"
							style="width:300px"></el-input>
          </el-form-item>
					<el-form-item v-for="(v,i) in addListruleForm.TagDetails" :key="i" :prop="'TagDetails.'+i+'.Name'"
						:rules="addListrules.TagDetails" :label="i==0?'标签名称:':''" label-width="100px">
            <el-input v-model.trim="v.Name" :maxlength="maxlength" @input="limit30" placeholder="请输入标签名称" style="width:300px"></el-input>
						<i v-show="addListruleForm.TagDetails.length > 1" class="el-icon-close"
							style="margin-left:15px;cursor:pointer;" @click="closeIconDel(i)"></i>
          </el-form-item>
        </el-form>
      </div>
			<el-button type="text" icon="el-icon-circle-plus-outline" style="margin-left:100px" @click="pushTag">添加标签
			</el-button>
      <div style="text-align:right">
        <el-button @click="addTagListClosed">取消</el-button>
        <el-button type="primary" @click="saveAddTagList('addTagListForm')">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import{
  groupadd,
}from "@/api/TurnTomySelf"
export default {
  props:{
    addTagListshow:{
      type:Boolean,
      default:false
    },
    addListruleForm:{
      type:Object,
      default:()=>{}
    },
  },
  data () {
    var IsEmptyList = (rule, value, callback) => {
        if(value == ''){
          callback(new Error("请输入标签组名称"))
        }else{
          callback()
        }
      };
      var IsEmpty = (rule, value, callback) => {
        if(value == ''){
          callback(new Error("请输入标签名称"))
        }else{
          callback()
        }
      };
     var limitCharacter = (rule, value, callback) => {
        let len = 0
        for (let i = 0; i < value.length; i++){
          var a = value.charAt(i);
          if (a.match(/[^\x00-\xff]/ig) != null) {
              //汉字占两个字符
              len += 2;
          }
          else {
              //其他占一个字符
              len += 1;
          } 
        }
        if(len>30){
          callback(new Error('最长可输入30个字符'))
        }else{
          callback()
        }
        
      };
    return {
      addListrules: {
					Name: [{
						required: true,
						trigger: 'blur',
						validator: IsEmptyList
					},{
						required: true,
						trigger: 'change',
						validator: limitCharacter
					}, ],
					TagDetails: [{
						required: true,
						trigger: 'blur',
						validator: IsEmpty
					},{
						required: true,
						trigger: 'change',
						validator: limitCharacter
					}, ]
      },
      addTagListLoading:false,
      maxlength:30,
    }
  },
  methods: {
     //关闭添加标签组回调
		addTagListClosed() {
      this.$emit('close')
      this.$refs.addTagListForm.resetFields()
    },
    //添加标签组 确定提交
		saveAddTagList(formName) {
      this.$refs[formName].validate((valid) => {
          if (valid) {
            this.togroupadd()
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error")
              if (isError[0].querySelector('input')) {
                isError[0].querySelector('input').focus();
              }
            },1)
            return false;
          }
        });
    },
    //添加标签组 添加标签
		pushTag() {
      let data = {
					Name: ''
      }
      this.addListruleForm.TagDetails.push(data)
      this.$forceUpdate()
    },
    //添加标签组 删除标签
		closeIconDel(e) {
				this.addListruleForm.TagDetails.splice(e, 1)
    },
    //添加标签组
		async togroupadd() {
      this.addTagListLoading = true
      try{
        let data = {
          Name:this.addListruleForm.Name,
          TagDetails:this.addListruleForm.TagDetails.map((v)=>{
            return v.Name
          }),
        }
        let result = await groupadd(data)
          if (result.IsSuccess) {
          this.$message.success('操作成功')
          this.$parent.getgroupList();
          // console.log(this.$emit)
        }
      }finally{
        this.addTagListLoading = false
        this.$parent.closetaglist()
      }
    },
    limit30(e){
      let len = 0
      let strnum = 0
      this.maxlength = 30//每次初始化一下输入最大值  不然在输入值满了之后 删除一位就无法再输入
        for (let i = 0; i < e.length; i++){
            var a = e.charAt(i);
            if (a.match(/[^\x00-\xff]/ig) != null) {
                //汉字占两个字符
                len += 2;
            }else {
                //其他占一个字符
                len += 1;
            }
            if(len<30){
              strnum+=1
            }else{
              this.maxlength = strnum
            }
          }
        // console.log(this.maxlength,len)
    },
  },
}
</script>

<style>

</style>