<template>
	<div class="container">
		<el-form :model="ruleForm">
			<div style="color: #999;margin-bottom: 10px;" v-if="balanceinfo.Id"><span style="margin-right:15px;">本金金额：{{balanceResult.Balance}}</span><span>赠送金金额：{{balanceResult.GiftBalance}}</span></div>
			<el-form-item label='' label-width="0px" prop="ChangeType">
				<label style="margin-right: 15px;"><span style="color: red;margin-right: 5px;">*</span>金额类型</label>
				<el-radio-group v-model="ruleForm.ChangeType">
					<el-radio :label="0">本金</el-radio>
					<el-radio :label="1">赠送金</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item  label-width="0px"  prop="ChangeBalance" v-if="ruleForm.ChangeType!=null">
				<label style="margin-right: 15px;"><span style="color: red;margin-right: 5px;">*</span>{{Title + '金额'}} </label>
				<el-input v-model="ruleForm.ChangeBalance" style="width: 360px;" @input='onlyNumber(ruleForm.ChangeBalance)'></el-input>
			</el-form-item>
			<el-form-item  label-width="0px"  prop="Cause" v-if="ruleForm.ChangeType!=null">
				<div style="display: flex;align-items: flex-start;">
					<label style="margin-right: 15px;"><span style="color: red;margin-right: 5px;">*</span>{{Title + '原因'}} </label>
					<el-input v-model="ruleForm.Reason" type="textarea" maxlength="500" style="width:360px;" :rows='4' resize='none'></el-input>
				</div>
				
			</el-form-item>
			<div style="text-align: center;margin-top: 30px;" v-if="ruleForm.ChangeType!=null">
				<el-button style="width: 120px;" @click="CloseBalance">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="SaveBalance" :loading="Sureloading">确认{{Title}}</el-button>
			</div>
		</el-form>
	</div>
</template>

<script>
	import config from '@/config/index'
	export default {
		name: 'balanceSet',
		props: {
			balanceIndex: {
				type: Number,
				default: () => {
					return 0
				}
			},
			balanceinfo: {
				type: Object,
				default: () => {
					return {}
				}
			},
			balanceResult: {
				type: Object,
				default: () => {
					return {}
				}
			},
		},
		data() {
			return {
				Title: '',
				ruleForm: {
					ChangeType: null,
					ChangeBalance: '',
					Reason:'',
				},
				Sureloading: false
			}
		},
		created() {
			this.ruleForm.ChangeType = null
			this.ruleForm.ChangeBalance = ''
			this.ruleForm.Reason = ''
			if (this.balanceIndex == 1) {
				this.Title = '充值'
			} else {
				this.Title = '扣减'
			}
			// console.log(this.balanceIndex, this.balanceinfo, '有传递过来吗')
		},
		methods: {
			//关闭弹窗
			CloseBalance() {
				this.$emit('closeBalance', false)
			},
			//确定
			SaveBalance() {
				if (!this.ruleForm.ChangeBalance) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请输入' + this.Title + '金额'
					});
					return
				}
				if (Number(this.ruleForm.ChangeBalance) < 0.01 || Number(this.ruleForm.ChangeBalance) > 99999) {
					this.$message({
						showClose: true,
						type: 'error',
						message: this.Title + '金额请输入0.01~99999元之间'
					});
					return
				}
				if (!this.ruleForm.Reason) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请输入' + this.Title + '原因'
					});
					return
				}
				if (this.ruleForm.Reason.length > 500) {
					this.$message({
						showClose: true,
						type: 'error',
						message: this.Title + '原因最多可输入500字'
					});
					return
				}
				this.$emit('getSelection', this.ruleForm)
				// console.log(this.ruleForm,'靠山山倒')

			},
			onlyNumber(obj) {
				if (obj != '' && obj.substr(0, 1) == '.') {
					obj = "";
				}
				obj = obj.replace(/^0*(0\.|[1-9])/, '$1'); //粘贴不生效
				obj = obj.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
				obj = obj.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
				obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
				obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
				if (obj.indexOf(".") < 0 && obj != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
					if (obj.substr(0, 1) == '0' && obj.length == 2) {
						obj = obj.substr(1, obj.length);
					}
				}
				this.ruleForm.ChangeBalance = obj
			},
		}
	}
</script>

<style lang="less" scoped>
</style>
