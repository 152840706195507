<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" custom-class="dialog-body-paddingTop-10" width="1000px" v-loading="loading" @close="close">
      <div class="flexRow">
        <div class="flexRow">
          <div class="label">关键字：</div>
          <el-input v-model.trim="keywords" placeholder="优惠券名称" style="width:200px"></el-input>
        </div>
        <el-button type="primary" style="margin-left:10px" @click="current=1,gettablelist()">查询</el-button>
      </div>
      <div style="margin-top:10px">
        <el-table :data="tablelist" v-loading="tableloading" ref="table" row-key="Id" @selection-change="selectionChange">
          <el-table-column type="selection" width="55" reserve-selection></el-table-column>
          <el-table-column prop="CouponName" label="优惠券" min-width="150">
            <template slot-scope="scope">
              <div class="ellipsis">{{scope.row.CouponName}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="UseTypeValue" label="类型"></el-table-column>
          <el-table-column prop="CouponContent" label="优惠内容" min-width="150">
            <template slot-scope="scope">
              <div class="ellipsis">{{scope.row.CouponContent}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="ActivityRangeTypeValue" label="适用商品"></el-table-column>
          <el-table-column prop="ValidPeriod" label="有效期" width="165">
            <template slot-scope="scope">
              <div class="ellipsis-two">{{scope.row.ValidPeriod}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="LeaveCount" label="剩余数量"></el-table-column>
          <el-table-column prop="" label="赠送数量">
            <template slot-scope="scope">
              <el-input v-model="scope.row.SendCount"	oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"
                @blur="changesend($event,scope.row)" maxlength="2"></el-input>
            </template>
          </el-table-column>
        </el-table>
        <div class="flexRow between" style="margin-top:10px" v-if="total">
          <div style="font-size:14px;color:#606266">
            已选中
            <span style="color:#f56c6c">{{chooselist.length}}</span>
            张优惠券
          </div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="current"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="size"
            layout="total, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
        <div class="flexRow between" style="margin-top:10px">
        <div></div>
        <div>
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="confirmsend">确定</el-button>
        </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  membersendCoupon
} from "@/api/sv3.0.0"
import apiList from '@/api/other'
export default {
  data () {
    return {
      loading:false,
      keywords:'',
      tablelist:[],
      tableloading:false,
      current:1,
      size:10,
      total:0,
      chooselist:[],
    }
  },
  watch:{
    '$attrs.visible'(val){
      if(val){
        this.gettablelist()
      }
    }
  },
  mounted () {
    // console.log(this.$attrs)
    // console.log(this.$listeners)
  },
  methods:{
    async confirmsend(){
      if(!this.chooselist||!this.chooselist.length){
        this.$message.error('请选择优惠券')
        return
      }
      try{
        // console.log(this.chooselist)
        this.loading = true
        let res = await membersendCoupon({
          MemberId:this.$attrs.Id,
          SendList:this.chooselist.map(v=>{
            return {
              Id:v.Id,
              SendCount:v.SendCount
            }
          }),
        })
        if(res.IsSuccess){
          if(res.Result&&res.Result.length){
            this.$confirm(res.Result.map(v=>`【${v}】`).join('、')+
            '发放数量超出剩余数量，发放失败，请选择其他优惠券，或修改发放数量。', '提示', {
              showConfirmButton: false,
              cancelButtonText: '好的',
              type: 'warning'
            }).then(() => {
              this.close()
            })
          }else{
            this.$message.success('赠送成功')
            this.close()
          }
        }
      }finally{
        this.loading = false
      }
    },
    close(){
      this.current = 1
      this.keywords = ''
      this.chooselist = []
      this.$nextTick(()=>{
        this.$refs.table.clearSelection()
      })
      this.$emit('close')
    },
    changesend(e,row){
      let count = row.SendCount
      if(count<1||count>99){
        this.$message.error('数量请设置在1~99之间，整数')
        count = 1
      }
      if(count>row.LeaveCount){
        count = row.LeaveCount
      }
      this.tablelist = this.tablelist.map(v=>{
        if(v.Id==row.Id){
          v.SendCount = count
        }
        return v
      })
      this.chooselist = this.chooselist.map(v=>{
        if(v.Id==row.Id){
          v.SendCount = count
        }
        return v
      })
    },
    selectionChange(list){
      this.chooselist = list
    },
    handleSizeChange(e){
      this.current = 1
      this.size = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.current = e
      this.gettablelist()
    },
    async gettablelist() {
      this.tableloading = true;
      try {
        let data = {
          Keyword : this.keywords,
          Skip: (this.current - 1) * this.size, // 略过数据
          Take: this.size, // 取的数据
          IsUse:true
        };
        let result = await apiList.activitycouponpopupList(data);
        // this.templateRadio=this.couponCheckedId
        // console.log(this.chooselist)
        this.tablelist = JSON.parse(JSON.stringify(result.Result.Results.map(v=>{
          let msg = this.chooselist.find(x=>x.Id==v.Id)
          if(msg&&msg.Id){
            v.SendCount = msg.SendCount
          }else{
            v.SendCount = 1
          }
          return v
        })))
        this.total = result.Result.Total;
        
      } finally {
        this.tableloading = false;
      }
    },
  }
}
</script>

<style lang="less" scoped>
.flexRow{
  display: flex;
  align-items: center;
}
.label{
  font-size: 14px;
  white-space: nowrap;
  color: #606266;
  line-height: 36px;
  font-weight: bold;
}
.between{
  justify-content: space-between;
}
</style>