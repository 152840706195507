<template>
  <div>
    <img v-if="show&&IsOpenLookUserPrivacyAuthority" src="@/assets/img/eyesHide.png" @click="decode" class="eyes" alt="">
  </div>
</template>

<script>
import {
  membermemberPhone,
  orderdesensitize
} from "@/api/goods"
import { mapGetters } from 'vuex'
export default {
  props: {
    parameter:{
      type:String|Number|Object|Array,
      default:''
    },
    data:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    type:{
      type:String,
      default:'Phone'
    },
    range:{
      type:String,
      default:'all'
    }
  },
  computed: {
    ...mapGetters([
      'IsOpenLookUserPrivacyAuthority'
    ]),
    show(){
      return this.parameter.indexOf('*')>-1
    }
  },
  data(){
    return {
      // show:true
    }
  },
  model:{
    prop:'parameter',
    event:'change'
  },
  mounted () {

    // console.log(this.show,this.IsOpenLookUserPrivacyAuthority)
  },
  methods:{
    change(val){
      this.$emit('change',val)
    },
    async decode(){
      try{
        let res = {}
        if(this.type=='Phone'&&this.range=='all'){
          res = await membermemberPhone(this.data)
          if(res.IsSuccess){
            this.change(res.Result.Phone)
          }
        }else if(this.range=='order'){//订单查手机号
          res = await orderdesensitize(this.data)
          if(res.IsSuccess){
            this.change(res.Result.ReceivePhone)
          }
        }else if(this.type=='IdCard'){//订单查身份证
          res = await orderdesensitize(this.data)
          if(res.IsSuccess){
            this.change(res.Result.IdCardNo)
          }
        }
      }finally{
        //
      }
    },
    pro(){
      return new Promise(res=>{
        setTimeout(() => {
          res()
        }, 500);
      })
    }
  }
}
</script>

<style lang="less" scoped>
.eyes{
  width:14px;height:14px;
  margin:5px 0px 0px 5px;
  // border: 1px solid black;
  cursor: pointer;
}
</style>